import { createSelector } from 'reselect';
import { schemas } from 'redux/middleware/clientMiddleware';

const prefix = 'osedu/topics/';

const GET_ALL_TOPICS_REQUEST = `${prefix}GET_ALL_TOPICS_REQUEST`;
const GET_ALL_TOPICS_SUCCESS = `${prefix}GET_ALL_TOPICS_SUCCESS`;
const GET_ALL_TOPICS_FAIL = `${prefix}GET_ALL_TOPICS_FAIL`;
const UPDATE_USER_TOPICS_REQUEST = `${prefix}UPDATE_USER_TOPICS_REQUEST`;
const UPDATE_USER_TOPICS_SUCCESS = `${prefix}UPDATE_USER_TOPICS_SUCCESS`;
const UPDATE_USER_TOPICS_FAIL = `${prefix}UPDATE_USER_TOPICS_FAIL`;
const TOGGLE_USER_TOPIC_REQUEST = `${prefix}TOGGLE_USER_TOPIC_REQUEST`;
const TOGGLE_USER_TOPIC_SUCCESS = `${prefix}TOGGLE_USER_TOPIC_SUCCESS`;
const TOGGLE_USER_TOPIC_FAIL = `${prefix}TOGGLE_USER_TOPIC_FAIL`;

// const initialSubjects = {
//   English: {
//     icon: 'book',
//   },
//   'English Literature': {
//     icon: 'book',
//     short: 'Englist Lit.',
//   },
//   History: {
//     icon: 'history',
//   },
//   'E.Math': {
//     icon: 'square-root-alt',
//   },
//   Malay: {
//     icon: 'language',
//   },
//   'A.Math': {
//     icon: 'infinity',
//   },
//   Biology: {
//     icon: 'biohazard',
//     default: true,
//   },
//   Physics: {
//     icon: 'apple-alt',
//     default: true,
//   },
//   Chemistry: {
//     icon: 'flask',
//     default: true,
//   },
// };

// Object.keys(initialSubjects).map(
//   key => (initialSubjects[key] = { ...initialSubjects[key], type: 'subject' })
// );

// const cl = (n, name) =>
//   Array(n)
//     .fill()
//     .map((_, i) => name + (i + 1));
// const initialLevelsTopics = [
//   cl(6, 'Primary '),
//   cl(5, 'Secondary '),
//   cl(2, 'JC'),
//   'Exams Strategies',
//   'Sports CCAs',
//   'Uniformed Groups CCAs',
//   'Performing Arts CCAs',
// ]
//   .flat()
//   .reduce((a, b) => {
//     a[b] = { icon: 'book', type: 'level' };
//     return a;
//   }, {});

// const initialTopics = {
//   ...initialSubjects,
//   ...initialLevelsTopics,
//   'Learning Styles': {
//     icon: 'graduation-cap',
//     default: true,
//   },
//   'Writing Tips': {
//     icon: 'pencil-alt',
//     default: true,
//   },
//   CCA: {
//     icon: 'swimmer',
//     default: true,
//   },
//   'Secondary School': {
//     icon: 'graduation-cap',
//     default: true,
//   },
// };

export default (state = {}, { type, result, data }) => {
  switch (type) {
    case GET_ALL_TOPICS_SUCCESS:
      return {
        ...state,
        ...result.data.reduce((a, b) => {
          a[b.value] = { ...state[b.value], ...b };
          return a;
        }, {}),
      };
    case UPDATE_USER_TOPICS_SUCCESS:
      return Object.assign(
        {},
        state,
        data.topics.reduce((a, b) => {
          a[b] = { ...state[b], subscribe: true };
          return a;
        }, {})
      );
    case TOGGLE_USER_TOPIC_REQUEST:
      return Object.assign({}, state, {
        [data.topic]: {
          ...state[data.topic],
          subscribe: !state[data.topic].subscribe,
        },
      });
    default:
      return state;
  }
};

const path = topic => `/forum/${topic}/`;

// Selectors
export const getTopics = state =>
  !!Object.keys(state.topics).length
    ? state.topics
    : (state.config &&
        state.config.dataByValue &&
        state.config.dataByValue.topics) ||
      {};
const isAuth = state => !!state.auth.user;
const getUser = state =>
  !!state.auth.user &&
  !!state.entities.users &&
  state.entities.users[state.auth.user];
const getPath = (_, props) => props.match.params.topic;

export const getSubscribedTopics = createSelector(
  [getUser],
  user => user.subscribedTopics
);

export const getSubjects = createSelector(
  [getTopics],
  topics =>
    Object.keys(topics)
      .map(topic =>
        topics[topic].type === 'subject'
          ? {
              value: topic,
              name: topic.length > 11 ? topics[topic].short : topic,
            }
          : null
      )
      .filter(Boolean)
);

export const getLevels = createSelector(
  [getTopics],
  topics =>
    Object.keys(topics)
      .map(topic => (topics[topic].type === 'level' ? topic : null))
      .filter(Boolean)
);

export const getTopicsState = createSelector(
  [getTopics, getSubscribedTopics],
  (topics, subscribedTopics) =>
    Object.keys(topics).map(topic => ({
      name: topic,
      ...topics[topic],
      path: path(topic),
      subscribed: subscribedTopics?.includes(topic),
    }))
);

export const getTopicsNav = createSelector(
  [getTopics, isAuth, getUser],
  (topics, isAuth, user) => {
    const holder = Object.keys(topics)
      .map(topic =>
        (isAuth &&
          user.subscribedTopics &&
          !!user.subscribedTopics.length &&
          user.subscribedTopics.includes(topic)) ||
        (!isAuth && topics[topic].default)
          ? {
              name: topics[topic].name,
              value: topics[topic].value,
              icon: topics[topic].icon,
              path: path(topic),
            }
          : null
      )
      .filter(Boolean);
    return holder;
  }
);

export const pathToTopic = createSelector(
  [getTopics, getPath],
  (topics, path) => {
    return Object.keys(topics).find(topic => topics[topic].value === path);
  }
);

export const topicToPath = topic => path(topic);

/**
 * Topic
 * @typedef  {Object} Topic
 * @property {string} topic     Topic
 * @property {Date}   active    Last Update
 * @property {number} questions Number of Questions
 * @property {number} followers Number of Followers
 */

/**
 * Get All Topics Information
 * @return {Promise<[Topic]>} Return Promise of Topics
 */
export function getAllTopics() {
  return dispatch =>
    dispatch({
      types: [
        GET_ALL_TOPICS_REQUEST,
        GET_ALL_TOPICS_SUCCESS,
        GET_ALL_TOPICS_FAIL,
      ],
      promise: client => client.post('/topics/list'),
      schema: schemas.TOPIC_ARRAY,
    });
}

/**
 * Update User's Topics (Add only)
 * @param  {Object} data
 * @param  {[string]} data.topics Topics
 * @return {Promise>}             Return Promise
 */
export function updateUserTopics(data) {
  return (dispatch, getState) => {
    data.author = getState().auth.user; //To be replace by backend internal auth token
    return dispatch({
      types: [
        UPDATE_USER_TOPICS_REQUEST,
        UPDATE_USER_TOPICS_SUCCESS,
        UPDATE_USER_TOPICS_FAIL,
      ],
      // promise: _ => client.post('/forum/user/topics', { data }),
      promise: _ => new Promise(res => setTimeout(() => res(), 2000)),
      data,
    });
  };
}

/**
 * Update User Topic (Toggle)
 * @param  {Object} data
 * @param  {string} data.topic Topic
 * @return {Promise>}          Return Promise
 */
export function toggleUserTopic(data) {
  return (dispatch, getState) => {
    data.author = getState().auth.user; //To be replace by backend internal auth token
    return dispatch({
      types: [
        TOGGLE_USER_TOPIC_REQUEST,
        TOGGLE_USER_TOPIC_SUCCESS,
        TOGGLE_USER_TOPIC_FAIL,
      ],
      // promise: _ => client.post('/forum/user/topic', { data }),
      promise: _ => new Promise(res => setTimeout(() => res(), 2000)),
      data,
    });
  };
}
