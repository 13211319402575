import { combineReducers } from 'redux';
import { schemas } from 'redux/middleware/clientMiddleware';
import { list as listUsers } from './users';
import { updateUserTopics } from './topics';
// import { uploadFile } from './s3';
import client, { uploadFile } from './forumAPI'; //For Dev

// For production
// Change _ to client
// Replace getState().auth.user with backend Auth Token to get user auth id

const prefix = 'osedu/forum/';

const CREATE_QUESTION_REQUEST = `${prefix}CREATE_QUESTION_REQUEST`;
const CREATE_QUESTION_SUCCESS = `${prefix}CREATE_QUESTION_SUCCESS`;
const CREATE_QUESTION_FAIL = `${prefix}CREATE_QUESTION_FAIL`;
const UPLOAD_IMAGES_REQUEST = `${prefix}UPLOAD_IMAGES_REQUEST`;
const UPLOAD_IMAGES_SUCCESS = `${prefix}UPLOAD_IMAGES_SUCCESS`;
const UPLOAD_IMAGES_FAIL = `${prefix}UPLOAD_IMAGES_FAIL`;
const GET_FEED_REQUEST = `${prefix}GET_FEED_REQUEST`;
const GET_FEED_SUCCESS = `${prefix}GET_FEED_SUCCESS`;
const GET_FEED_FAIL = `${prefix}GET_FEED_FAIL`;
const READ_REQUEST = `${prefix}READ_REQUEST`;
const READ_SUCCESS = `${prefix}READ_SUCCESS`;
const READ_FAIL = `${prefix}READ_FAIL`;
const CHANGE_ACCEPTED_ANSWER_REQUEST = `${prefix}CHANGE_ACCEPTED_ANSWER_REQUEST`;
const CHANGE_ACCEPTED_ANSWER_SUCCESS = `${prefix}CHANGE_ACCEPTED_ANSWER_SUCCESS`;
const CHANGE_ACCEPTED_ANSWER_FAIL = `${prefix}CHANGE_ACCEPTED_ANSWER_FAIL`;
const ADD_ANSWER_REQUEST = `${prefix}ADD_ANSWER_REQUEST`;
const ADD_ANSWER_SUCCESS = `${prefix}ADD_ANSWER_SUCCESS`;
const ADD_ANSWER_FAIL = `${prefix}ADD_ANSWER_FAIL`;
const GET_POSTS_REQUEST = `${prefix}GET_POSTS_REQUEST`;
const GET_POSTS_SUCCESS = `${prefix}GET_POSTS_SUCCESS`;
const GET_POSTS_FAIL = `${prefix}GET_POSTS_FAIL`;
const GET_ACTIVITIES_REQUEST = `${prefix}GET_ACTIVITIES_REQUEST`;
const GET_ACTIVITIES_SUCCESS = `${prefix}GET_ACTIVITIES_SUCCESS`;
const GET_ACTIVITIES_FAIL = `${prefix}GET_ACTIVITIES_FAIL`;
const CREATE_COMMENT_REQUEST = `${prefix}CREATE_COMMENT_REQUEST`;
const CREATE_COMMENT_SUCCESS = `${prefix}CREATE_COMMENT_SUCCESS`;
const CREATE_COMMENT_FAIL = `${prefix}CREATE_COMMENT_FAIL`;
const UPDATE_VOTE_REQUEST = `${prefix}UPDATE_VOTE_REQUEST`;
const UPDATE_VOTE_SUCCESS = `${prefix}UPDATE_VOTE_SUCCESS`;
const UPDATE_VOTE_FAIL = `${prefix}UPDATE_VOTE_FAIL`;
const ONBOARDING_REQUEST = `${prefix}ONBOARDING_REQUEST`;
const ONBOARDING_SUCCESS = `${prefix}ONBOARDING_SUCCESS`;
const ONBOARDING_FAIL = `${prefix}ONBOARDING_FAIL`;
const GET_TOP_REQUEST = `${prefix}GET_TOP_REQUEST`;
const GET_TOP_SUCCESS = `${prefix}GET_TOP_SUCCESS`;
const GET_TOP_FAIL = `${prefix}GET_TOP_FAIL`;
const UPDATE_SETTINGUP_REQUEST = `${prefix}UPDATE_SETTINGUP_REQUEST`;
const UPDATE_SETTINGUP_SUCCESS = `${prefix}UPDATE_SETTINGUP_SUCCESS`;
const UPDATE_SETTINGUP_FAIL = `${prefix}UPDATE_SETTINGUP_FAIL`;
const QUESTION_SUB_REQUEST = `${prefix}QUESTION_SUB_REQUEST`;
const QUESTION_SUB_SUCCESS = `${prefix}QUESTION_SUB_SUCCESS`;
const QUESTION_SUB_FAIL = `${prefix}QUESTION_SUB_FAIL`;

const addCommentToState = (state, { question_id, answer_id }, comment) => {
  const s = Object.assign({}, state);
  if (answer_id) {
    const answer = s[question_id].answers.find(({ _id }) => _id === answer_id);
    answer.comments.push(comment);
  } else s[question_id].comments.push(comment);
  return s;
};

const voteCardAlgor = (votes, preVote, vote) => {
  if (preVote === true) {
    if (vote === false) return votes - 2;
    else return votes - 1;
  } else if (preVote === false) {
    if (vote === true) return votes + 2;
    else return votes + 1;
  } else {
    if (vote === true) return votes + 1;
    else return votes - 1;
  }
};

const updateVoteCard = (card, vote) => {
  card.votes = voteCardAlgor(card.votes, card.vote, vote);
  card.vote = vote;
};

const updateVoteComment = (card, comment_id) => {
  const comments = [...card.comments];
  const comment = comments.find(({ _id }) => _id === comment_id);
  if (!comment.vote) comment.votes++;
  else comment.votes--;
  comment.vote = !comment.vote;
  card.comments = comments;
};

const updateVoteToState = (
  state,
  { question_id, answer_id, comment_id, vote }
) => {
  const s = Object.assign({}, state);
  let card = s[question_id];
  if (answer_id) card = card.answers.find(({ _id }) => _id === answer_id);
  if (comment_id) updateVoteComment(card, comment_id);
  else updateVoteCard(card, vote);
  return s;
};

const questions = (state = {}, { result, type, data }) => {
  switch (type) {
    case READ_SUCCESS:
      return Object.assign({}, state, { [result.data._id]: result.data });
    case CHANGE_ACCEPTED_ANSWER_REQUEST:
      return Object.assign({}, state, {
        [data._id]: { ...state[data._id], acceptedAnswer: data.acceptedAnswer },
      });
    case CHANGE_ACCEPTED_ANSWER_FAIL:
      return Object.assign({}, state, {
        [data._id]: { ...state[data._id], acceptedAnswer: data.previous },
      });
    case ADD_ANSWER_SUCCESS:
      return Object.assign({}, state, {
        [data._id]: {
          ...state[data._id],
          answers: state[data._id].answers.concat([result.data]),
        },
      });
    case CREATE_COMMENT_SUCCESS:
      return addCommentToState(state, data, result.data);
    case UPDATE_VOTE_REQUEST:
      return updateVoteToState(state, data);
    case QUESTION_SUB_SUCCESS:
      return !data.topic
        ? Object.assign({}, state, {
            [data._id]: {
              ...state[data._id],
              subscribe: !state[data._id].subscribe,
            },
          })
        : state;
    default:
      return state;
  }
};

const posts = (state = {}, { result, type, data }) => {
  switch (type) {
    case GET_POSTS_SUCCESS:
      return Object.assign({}, state, { [data.topic]: result.data });
    case GET_FEED_SUCCESS:
      return Object.assign({}, state, { home: result.data });
    case QUESTION_SUB_SUCCESS:
      if (data.topic && state[data.topic]) {
        return Object.assign({}, state, {
          [data.topic]: state[data.topic].map(post =>
            post._id === data._id
              ? { ...post, subscribe: !post.subscribe }
              : post
          ),
        });
      } else return state;
    default:
      return state;
  }
};

const activities = (state = {}, { type, result, data }) => {
  switch (type) {
    case GET_ACTIVITIES_SUCCESS:
      const s = Object.assign({}, state);
      s[data._id] = result.data;
      return s;
    default:
      return state;
  }
};

const onboarding = (state = false, { type }) => {
  switch (type) {
    case ONBOARDING_SUCCESS:
      return true;
    default:
      return state;
  }
};

const initialSettingUp = [
  'Visit your feed',
  'Follow 3 topics',
  'Follow 5 more topics',
  'Upvote 5 good answers',
  'Ask a question',
  'Answer a question',
].map(text => ({ text, check: false }));

const settingUp = (state = initialSettingUp, { type, data }) => {
  switch (type) {
    case UPDATE_SETTINGUP_SUCCESS:
      console.log(data);
      const a = [...state];
      a[data.id].check = true;
      return a;
    default:
      return state;
  }
};

const topTutors = (state = [], { type, result }) => {
  switch (type) {
    case GET_TOP_SUCCESS:
      return result.data;
    default:
      return state;
  }
};

export default combineReducers({
  questions,
  posts,
  activities,
  onboarding,
  settingUp,
  topTutors,
});

/**
 * Image
 * @typedef  {Object} Image
 * @property {string} url  Image URL in S3
 * @property {string} name Name of Image
 */

/**
 * Create Question
 * @param  {Object} data             Question Data Object
 * @param  {string} data.question    Question itself
 * @param  {string} data.description Question's description
 * @param  {string} data.topic       Question's topic
 * @param  {[string]?} data.tags     Question's tags
 * @param  {[Image]?} data.images    Image Object
 * @return {Promise<string>}         Return Question ID
 */
function postQuestion(data) {
  return (dispatch, getState) => {
    data.author = getState().auth.user; //To be replace by backend internal auth token
    return dispatch({
      types: [
        CREATE_QUESTION_REQUEST,
        CREATE_QUESTION_SUCCESS,
        CREATE_QUESTION_FAIL,
      ],
      promise: _ => client.post('/forum/create', { data }),
      schema: schemas.QUESTION,
    }).then(({ data: { id } }) => id);
  };
}

/**
 * Convert Data Url to Blob
 * @param  {string} dataurl Image in Data URL format
 * @return {Blob}           Image Blob
 */
function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) u8arr[n] = bstr.charCodeAt(n);
  return new Blob([u8arr], { type: mime });
}

/**
 * ImageRaw
 * @typedef  {Object} ImageRaw
 * @property {string} data Url Data
 * @property {string} name Name of Image
 */

/**
 * Create Question
 * Check if Images exist, if exist, send to S3 via uploadfile
 * @param  {Object} data             Question Data Object
 * @param  {string} data.question    Question itself
 * @param  {string} data.description Question's description
 * @param  {string} data.topic       Question's topic
 * @param  {[string]?} data.tags     Question's tags
 * @param  {[ImageRaw]?} data.images Image Object
 * @return {Promise<string>}         Return Question ID
 */
export function createQuestion({ question, description, topic, tags, images }) {
  if (images && images.length > 0) {
    return dispatch =>
      dispatch({
        types: [
          UPLOAD_IMAGES_REQUEST,
          UPLOAD_IMAGES_SUCCESS,
          UPLOAD_IMAGES_FAIL,
        ],
        promise: _ =>
          Promise.all(
            images.map(
              ({ data, name }) =>
                new Promise(res =>
                  dispatch(uploadFile(dataURLtoBlob(data))).then(url =>
                    res({ url, name })
                  )
                )
            )
          ),
      }).then(imagesInfo =>
        dispatch(
          postQuestion({
            question,
            description,
            topic,
            tags,
            images: imagesInfo,
          })
        )
      );
  } else return postQuestion({ question, description, topic, tags });
}

/**
 * Post Answer Data to be used for PostCard
 * @typedef  {Object} PostAnswer
 * @property {string} answer   Answer Text
 * @property {string} author   Author User ID
 * @property {number} votes    Number of Votes
 * @property {number} comments Number of Comments
 * @property {number} views    Number of Views
 * @property {Date} createdAt  Timestamp Created
 */

/**
 * Post Question Data to be used for PostCard
 * @typedef  {Object} Post
 * @property {string} _id         Question ID
 * @property {string} question    Question Text
 * @property {number} views       Number of Views
 * @property {number} votes       Number of Votes
 * @property {[string]?} tags     Question Tags
 * @property {string} author      Author User ID
 * @property {boolean} subscribe  User's subscription to the Question // Check with backend if user is subscribed to this question
 * @property {number} answers     Number of Answers
 * @property {PostAnswer?} answer Answer itself
 * @property {Date} createdAt     Timestamp Created
 */

/**
 * Get Feed for Personal Feed, Post w Answer
 * @return {Promise<[Post]>} Return Array of Post
 */
export function getFeed() {
  return (dispatch, getState) => {
    const _id = getState().auth.user; //To be replace by backend internal auth token
    if (!_id) return null; //To be replace by backend internal auth token
    return dispatch({
      types: [GET_FEED_REQUEST, GET_FEED_SUCCESS, GET_FEED_FAIL],
      promise: _ => client.post('/forum/feed', { data: { _id } }),
      schema: schemas.POST_ARRAY,
    }).then(({ data }) =>
      dispatch(
        listUsers({
          filter: {
            _id: [
              ...new Set(
                data
                  .map(({ author, answer }) => [
                    author,
                    answer ? answer.author : undefined,
                  ])
                  .flat()
                  .filter(Boolean)
              ),
            ],
          },
        })
      ).then(_ => data)
    );
  };
}

/**
 * Comment
 * @typedef   {Object} Comment
 * @property  {string} comment   Comment
 * @property  {number | 0} votes Number of votes
 * @property  {boolean?} vote    Vote > Check user and check backend
 * @property  {string} author    Author of Comment
 * @property  {string} _id       Comment Id
 */

/**
 * Answer
 * @typedef   {Object} Answer
 * @property  {string} answer           Answer
 * @property  {number | 0} views        Views
 * @property  {number | 0} votes        Votes
 * @property  {boolean?} vote           Vote > Check user and check backend
 * @property  {string} author           Author
 * @property  {[Comment] | []} comments Comments
 * @property  {[Image] | []} images     Image Object
 * @property  {Date} createdAt          Timestamp createdAt
 * @property  {Date} updatedAt          Timestamp updatedAt
 * @property  {string} _id              Comment Id
 */

/**
 * Question
 * @typedef   {Object} Question
 * @property  {string} question         Question
 * @property  {string} description      Description
 * @property  {number | 0} views        Views
 * @property  {number | 0} votes        Votes
 * @property  {boolean?} vote           Vote > Check user and check backend
 * @property  {[string] | []} tags      Tags
 * @property  {string} author           Author
 * @property  {[Comment] | []} comments Comments
 * @property  {[Image] | []} images     Image Object
 * @property  {string?} acceptedAnswer  Accepted Answer's Id
 * @property  {[Answer] | []} answers   Answers
 * @property  {boolean} subscribe       User's subscription to the Question // Check with backend if user is subscribed to this question
 * @property  {Date} createdAt          Timestamp createdAt
 * @property  {Date} updatedAt          Timestamp updatedAt
 * @property  {string} _id              Question Id
 */

/**
 * Get Question
 * @param  {string} _id        Question id
 * @return {Promise<Question>} Return Promise of Question
 */
export function getQuestion(_id) {
  return dispatch => {
    return dispatch({
      types: [READ_REQUEST, READ_SUCCESS, READ_FAIL],
      promise: _ => client.post('/forum/read', { data: { _id } }),
      schema: schemas.QUESTION,
    }).then(({ data }) => {
      if (!data) {
        throw Error('Question not found');
      }
      const { comments, answers } = data;
      const users = [data.author];
      if (comments.length > 0)
        users.push(...comments.map(({ author }) => author));
      if (answers.length > 0)
        users.push(
          ...answers
            .map(({ author, comments }) =>
              [
                author,
                comments.length > 0
                  ? comments.map(({ author }) => author)
                  : undefined,
              ].flat()
            )
            .flat()
        );
      return dispatch(listUsers({ filter: { _id: [...new Set(users)] } })).then(
        () => data
      );
    });
  };
}

/**
 * Update Accepted Answer
 * @param  {Object} data                Data Object
 * @param  {string} data._id            Question ID
 * @param  {string} data.acceptedAnswer Answer ID
 * @return {Promise<void>}              Return Promise
 */
export function updateAcceptedAnswer(data) {
  return (dispatch, getState) => {
    return dispatch({
      types: [
        CHANGE_ACCEPTED_ANSWER_REQUEST,
        CHANGE_ACCEPTED_ANSWER_SUCCESS,
        CHANGE_ACCEPTED_ANSWER_FAIL,
      ],
      // promise: _ => client.post('/forum/acceptedAnswer', { data }),
      promise: _ => new Promise(res => res(data)),
      data: {
        ...data,
        previous: getState().forum.questions[data._id].acceptedAnswer,
      },
    });
  };
}

/**
 * Create Answer
 * @param  {Object} data          Data Object
 * @param  {string} data._id      Question ID
 * @param  {string} data.answer   Answer text
 * @param  {[Image]?} data.images Image Object
 * @return {Promise<Answer>}      Return Promise of Answer
 */
function postAnswer(data) {
  return (dispatch, getState) => {
    data.author = getState().auth.user; //To be replace by backend internal auth token
    return dispatch({
      types: [ADD_ANSWER_REQUEST, ADD_ANSWER_SUCCESS, ADD_ANSWER_FAIL],
      promise: _ => client.post('/forum/answer', { data }),
      data,
      schema: schemas.ANSWER,
    });
  };
}

/**
 * Add Answers to Question
 * Check if Images exist, if exist, process them and send to s3
 * @param  {Object} data             Data Object
 * @param  {string} data._id         Question ID
 * @param  {string} data.answer      Answer text
 * @param  {[ImageRaw]?} data.images Image Object w Data URL
 * @return {Promise}                 Return Promise
 */
export function createAnswer({ _id, answer, images = [] }) {
  if (images.length > 0) {
    return dispatch =>
      dispatch({
        types: [
          UPLOAD_IMAGES_REQUEST,
          UPLOAD_IMAGES_SUCCESS,
          UPLOAD_IMAGES_FAIL,
        ],
        promise: _ =>
          Promise.all(
            images.map(
              ({ data, name }) =>
                new Promise(res =>
                  dispatch(uploadFile(dataURLtoBlob(data))).then(url =>
                    res({ url, name })
                  )
                )
            )
          ),
      }).then(imagesInfo =>
        dispatch(postAnswer({ _id, answer, images: imagesInfo }))
      );
  } else return postAnswer({ _id, answer });
}

/**
 * Get Post for Topic Discussion Page
 * @param  {Object} data         Data Object
 * @param  {string} data.topic   Topic
 * @param  {string?} data.search Search by term
 * @param  {string?} data.select Sort by, sub or active
 * @return {Promise<[Post]>}     Return Promise
 */
export function getPosts(data) {
  return dispatch =>
    dispatch({
      types: [GET_POSTS_REQUEST, GET_POSTS_SUCCESS, GET_POSTS_FAIL],
      promise: _ => client.post('/forum/posts', { data }),
      data,
      schema: schemas.POST_ARRAY,
    }).then(({ data }) =>
      dispatch(
        listUsers({
          filter: { _id: [...new Set(data.map(({ author }) => author))] },
        })
      )
    );
}

/**
 * AnswerPreview
 * @typedef  {Object} AnswerPreview
 * @property {string} question Question
 * @property {string} answer   Answer
 * @property {string} _id      Question ID
 */

/**
 * Get Activities of User
 * @param  {Object} data
 * @param  {string} data._id User ID
 * @return {Promise<[Post | AnswerPreview]>} Return Promise of Post wo Answer or AnswerPreview
 */
export function getActivities(data) {
  return dispatch =>
    dispatch({
      types: [
        GET_ACTIVITIES_REQUEST,
        GET_ACTIVITIES_SUCCESS,
        GET_ACTIVITIES_FAIL,
      ],
      promise: _ => client.post('/forum/activities', { data }),
      schema: schemas.POST,
      data,
    });
}

/**
 * Create Comment
 * @param  {Object} data
 * @param  {string} data.comment     Comment
 * @param  {string} data.question_id Question ID
 * @param  {string?} data.answer_id  Answer ID
 * @return {Promise<[Comment]>} Return Promise of Comment
 */
export function createComment(data) {
  return (dispatch, getState) => {
    data.author = getState().auth.user; //To be replace by backend internal auth token
    return dispatch({
      types: [
        CREATE_COMMENT_REQUEST,
        CREATE_COMMENT_SUCCESS,
        CREATE_COMMENT_FAIL,
      ],
      promise: _ => client.post('/forum/comment', { data }),
      schema: schemas.COMMENT,
      data,
    });
  };
}

/**
 * Voting
 * @param  {Object} data
 * @param  {string} data.question_id Question ID
 * @param  {string?} data.answer_id  Answer ID
 * @param  {string?} data.comment_id Comment ID
 * @param  {boolean?} data.vote      Vote, True: Upvote, False: Downvote, Null: Remove Vote
 * @return {Promise<[Comment]>} Return Promise of Comment
 */
export function updateVote(data) {
  return (dispatch, getState) => {
    data.author = getState().auth.user; //To be replace by backend internal auth token
    return dispatch({
      types: [UPDATE_VOTE_REQUEST, UPDATE_VOTE_SUCCESS, UPDATE_VOTE_FAIL],
      promise: _ => client.post('/forum/vote', { data }),
      data,
    });
  };
}

/**
 * Onboarding
 * @param  {Object} data
 * @param  {[string]?} data.topics Topics
 * @return {Promise}               Return Promise
 */
export function onboard(data) {
  return dispatch =>
    dispatch({
      types: [ONBOARDING_REQUEST, ONBOARDING_SUCCESS, ONBOARDING_FAIL],
      promise: _ => dispatch(updateUserTopics(data)),
    });
}

/**
 * Get Top Tutor
 * @return {Promise<[string]>} Return Array of Top Tutor
 */
export function getTop() {
  return dispatch =>
    dispatch({
      types: [GET_TOP_REQUEST, GET_TOP_SUCCESS, GET_TOP_FAIL],
      promise: _ =>
        client
          .post('/forum/top')
          .then(({ data }) =>
            dispatch(listUsers({ filter: { _id: data } })).then(() => ({
              data,
            }))
          ),
    });
}

/**
 * Trigger when one of the setting up criteria is done
 * @param  {Object} data
 * @param  {string} data.id Setting up ID
 * @return {Promise}
 */
export function updateSettingUp(data) {
  return dispatch =>
    dispatch({
      types: [
        UPDATE_SETTINGUP_REQUEST,
        UPDATE_SETTINGUP_SUCCESS,
        UPDATE_SETTINGUP_FAIL,
      ],
      // promise: _ => client.post('/forum/settingup'),
      promise: _ => new Promise(res => res()),
      data,
    });
}

/**
 * Subscribe to Question
 * @param  {Object} data
 * @param  {string} data._id    Question ID
 * @param  {string?} data.topic Question Topic
 * @return {Promise}
 */
export function subscribeQuestion(data) {
  return (dispatch, getState) => {
    data.author = getState().auth.user; //To be replace by backend internal auth token
    return dispatch({
      types: [QUESTION_SUB_REQUEST, QUESTION_SUB_SUCCESS, QUESTION_SUB_FAIL],
      // promise: _ => client.post('/forum/questionsub'),
      promise: _ => new Promise(res => res()),
      data,
    });
  };
}
