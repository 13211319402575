import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { logout } from 'redux/modules/auth';

const Logout = props => {
  const handleLogout = event => {
    event.preventDefault();
    props.logout();
    toastr.success('Successful Logout', 'You have been logged out.');

    // Hard refresh hack
    window.location = window.location.href;
  };

  return (
    <li>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={handleLogout}>
        <i className="icon-log-out" />
        Logout
      </a>
    </li>
  );
};

export default connect(
  null,
  dispatch => bindActionCreators({ logout }, dispatch)
)(Logout);
