/**
 * Dev API, For Development use
 * To be deleted once backend is built
 * Follow forum.model.js for Schema
 * Topics > Questions > Answers > Comments
 * Topics > Questions > Comments
 */

import { isEmpty } from 'lodash';

/**
 * Topic Template
 * @param  {Object} data        Topic Object
 * @param  {string} data.topic  Topic
 * @param  {{}?} data.questions Topic Questions
 * @param  {Date?} data.createdAt                       Timestamp createdAt
 * @param  {Date?} data.updatedAt                       Timestamp updatedAt
 */
const topicTemplate = ({
  topic,
  questions = {},
  createdAt = new Date(),
  updatedAt = new Date(),
}) => ({
  topic,
  questions,
  createdAt,
  updatedAt,
});

/**
 * Question Template
 * @param  {Object} data                                Question Object
 * @param  {string} data.question                       Question Test
 * @param  {string} data.description                    Question description
 * @param  {number?} data.views                         Question views
 * @param  {number?} data.votes                         Question votes
 * @param  {[string]?} data.tags                        Question tags
 * @param  {string} data.author                         Question author
 * @param  {{}?} data.comments                          Question comments
 * @param  {{url: string, name: string}[]?} data.images Image Object
 * @param  {string?} data.acceptedAnswer                Accepted Answer
 * @param  {{}?} data.answers                           Answers
 * @param  {Date?} data.createdAt                       Timestamp createdAt
 * @param  {Date?} data.updatedAt                       Timestamp updatedAt
 */
const questionTemplate = ({
  question,
  description,
  views = 0,
  votes = 0,
  tags = [],
  author,
  comments = {},
  images = [],
  acceptedAnswer = null,
  answers = {},
  createdAt = new Date(),
  updatedAt = new Date(),
}) => ({
  question,
  description,
  views,
  votes,
  tags,
  author,
  comments,
  images,
  acceptedAnswer,
  answers,
  createdAt,
  updatedAt,
});

/**
 * Answer Template
 * @param  {Object} data                                Answer Object
 * @param  {string} data.answer                         Answer answer
 * @param  {number?} data.views                         Answer views
 * @param  {number?} data.votes                         Answer votes
 * @param  {string} data.author                         Answer author
 * @param  {{}?} data.comments                          Answer comments
 * @param  {{url: string, name: string}[]?} data.images Image Object
 * @param  {Date?} data.createdAt                       Timestamp createdAt
 * @param  {Date?} data.updatedAt                       Timestamp updatedAt
 */
const answerTemplate = ({
  answer,
  views = 0,
  votes = 0,
  author,
  comments = {},
  images = [],
  createdAt = new Date(),
  updatedAt = new Date(),
}) => ({
  answer,
  views,
  votes,
  author,
  comments,
  images,
  createdAt,
  updatedAt,
});

/**
 * Comment Template
 * @param  {Object} data         Comment Data Object
 * @param  {string} data.comment Comment
 * @param  {number?} data.votes  Number of votes
 * @param  {string} data.author  Author of Comment
 */
const commentTemplate = ({ comment, votes = 0, author }) => ({
  comment,
  votes,
  author,
});

// 10 days ago
const TENDA = new Date();
TENDA.setDate(TENDA.getDate() - 10);

const FIVEDA = new Date();
FIVEDA.setDate(FIVEDA.getDate() - 5);

const FAKETEXT = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dapibus ultrices in iaculis nunc sed augue lacus. Quam nulla porttitor massa id neque aliquam. Ultrices mi tempus imperdiet nulla malesuada. Eros in cursus turpis massa tincidunt dui ut ornare lectus. Egestas sed sed risus pretium. Lorem dolor sed viverra ipsum. 

Gravida rutrum quisque non tellus. Rutrum tellus pellentesque eu tincidunt tortor. Sed blandit libero volutpat sed cras ornare. Et netus et malesuada fames ac. Ultrices eros in cursus turpis massa tincidunt dui ut ornare. Lacus sed viverra tellus in. Sollicitudin ac orci phasellus egestas. Purus in mollis nunc sed. Sollicitudin ac orci phasellus egestas tellus rutrum tellus pellentesque. Interdum consectetur libero id faucibus nisl tincidunt eget`;

// Fake mongodb Database
let database = {
  topics: {
    '78a39f97-22fc-4f35-a647-e2b56704fe9c': topicTemplate({
      topic: 'Secondary School',
      questions: {
        '987dcbb4-c3a6-4c87-8902-9e2d970bec21': questionTemplate({
          question: 'How do you do well for “N” Levels?',
          description: FAKETEXT,
          views: 400,
          votes: 30,
          tags: ['exam strategies', 'sec 4 na'],
          author: '5eeb3d5d3534ad14339dda03',
          images: [
            {
              url: 'https://osedu.s3.amazonaws.com/SylZGJY_1P.png',
              name: 'something',
            },
            {
              url: 'https://osedu.s3.amazonaws.com/HJlsEeeP1D.png',
              name: 'something 2',
            },
          ],
          acceptedAnswer: 'c28b1aae-94ee-48eb-bb8f-2f2a36615f62',
          comments: {
            '62fd6341-631d-430f-8d1b-79484c28a7f9': commentTemplate({
              comment: 'Rutrum tellus pellentesque eu tincidunt ',
              votes: 1,
              author: '5eeb3d5d3534ad14339dda03',
            }),
          },
          answers: {
            'c28b1aae-94ee-48eb-bb8f-2f2a36615f62': answerTemplate({
              answer: FAKETEXT,
              votes: 9,
              views: 10,
              author: '5f01eb1d3534ad14339f8a46',
              comments: {
                'd5a42e8b-f9d4-4634-bae2-853753aae25a': commentTemplate({
                  comment: 'but what if xyz happens?',
                  votes: 9,
                  author: '5eeb3d5d3534ad14339dda03',
                }),
                'd5a42e8b-f9d4-4634-bae2-853753aae25b': commentTemplate({
                  comment: 'then perhaps you can do Y',
                  votes: 0,
                  author: '5f01eb1d3534ad14339f8a46',
                }),
              },
              createdAt: TENDA,
              updatedAt: TENDA,
            }),
            '3ce8b054-8057-40a6-97a3-cf1e65b1c4e6': answerTemplate({
              answer: FAKETEXT,
              author: '5f01eb1d3534ad14339f8a46',
              votes: -1,
              views: 1,
              createdAt: FIVEDA,
              updatedAt: FIVEDA,
            }),
          },
          createdAt: TENDA,
          updatedAt: TENDA,
        }),
        'bcf98726-37bf-4c78-87fb-5f5fa58cebc9': questionTemplate({
          question:
            'What are the study habits a visual learner can pick up to score better and enjoy studying?',
          description: FAKETEXT,
          views: 400,
          votes: 30,
          tags: ['exam strategies', 'sec 4 na'],
          author: '5f01eb1d3534ad14339f8a46',
          answers: {
            '60df7d79-ce32-4ba6-80e6-09a5f153ea4c': answerTemplate({
              answer:
                "For an all-rounded strategy I'll be discussing: \n  1. How to make the most out of class \n  2. How to take notes effectively (visually!) \n  3. Something \n  4. Something More",
              author: '5eeb3d5d3534ad14339dda03',
              votes: 1,
              views: 10,
              createdAt: TENDA,
              updatedAt: TENDA,
            }),
          },
        }),
        '9866f2e2-64a3-48e9-b103-a88c7439b778': questionTemplate({
          question: 'Confused by Graphing Techniques',
          description: FAKETEXT,
          views: 0,
          votes: 0,
          tags: ['sec 4 exp', 'e.math', 'graphs'],
          author: '5eeb3d5d3534ad14339dda03',
        }),
        '66ceabfc-fbb9-419e-8ae1-695639582e3f': questionTemplate({
          question:
            'What’s the trick to answering “Area Under Graph” questions?',
          description: FAKETEXT,
          views: 10,
          votes: 3,
          tags: ['sec 4 exp', 'e.math', 'graphs'],
          author: '5eeb3d5d3534ad14339dda03',
        }),
      },
    }),
  },
};

// User Base
// Subscribe
// Vote

/* eslint-disable */
/**
 * Creates a uuid to mimic mongodb ObjectID
 * @return {string} Image Url
 */
function uuidGenerator() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

// console.log(uuidGenerator());

// API <<<<<<<<<<<<<<<<<
function post(url, { data } = {}) {
  return new Promise((res, reject) => {
    const returnData = data => res({ data });
    setTimeout(() => {
      switch (url) {
        case '/forum/create':
          returnData(createQuestion(data));
          break;
        case '/forum/feed':
          returnData(getFeed(data));
          break;
        case '/forum/read':
          returnData(getQuestion(data));
          break;
        case '/forum/answer':
          returnData(addAnswer(data));
          break;
        case '/forum/posts':
          returnData(getPosts(data));
          break;
        case '/forum/alltopics':
          returnData(getAllTopics());
          break;
        case '/forum/activities':
          returnData(getActivities(data));
          break;
        case '/forum/comment':
          returnData(createComment(data));
          break;
        case '/forum/vote':
          returnData(updateVote(data));
          break;
        case '/forum/top':
          returnData([
            '5a962b49252b0d08140f8840',
            '5a962be9252b0d08140f8842',
            '5a962c42252b0d08140f8843',
            '5b0e2a036f98a525d0e92dc8',
            '5d0b0fb2cd416319bb34a33f',
          ]);
          break;
        default:
          reject('Unknown url');
          break;
      }
    }, 0);
  });
}

export default { post };

function findTopicCollection(topic) {
  return Object.values(database.topics).find(a => a.topic === topic);
}

function findQuestionCollection(question) {
  return Object.values(database.topics).find(a => a.questions[question]);
}

function findQuestion(question) {
  const topicCollection = findQuestionCollection(question);
  return topicCollection
    ? {
        question: topicCollection.questions[question],
        topic: topicCollection.topic,
      }
    : null;
}

function serializeAnswer(_id, answer) {
  const comments = answer.comments;
  answer.comments = !isEmpty(comments)
    ? Object.keys(comments).map(key => ({ ...comments[key], _id: key }))
    : [];
  return { ...answer, _id };
}

/**
 * Create Question
 * @param  {Object} data                                Question Data Object
 * @param  {string} data.question                       Question itself
 * @param  {string} data.description                    Question's description
 * @param  {string} data.topic                          Question Topic
 * @param  {[string]?} data.tags                        Question's Tags
 * @param  {{url: string, name: string}[]?} data.images Image Object
 * @return {Promise<string>|Promise<number>}            Return Id of the question generated
 */
function createQuestion({
  question,
  description,
  topic,
  tags,
  images,
  author,
}) {
  const id = uuidGenerator();
  let topicCol = findTopicCollection(topic);
  if (!topicCol)
    topicCol = database.topics[uuidGenerator()] = topicTemplate({ topic });
  topicCol.questions[id] = questionTemplate({
    tags,
    question,
    description,
    images,
    author,
  });
  return { id };
}

/**
 * Post's Answer Data to be used for PostCard
 * @typedef {Object} PostAnswer
 * @property {string} answer   Answer Text
 * @property {string} author   Author User ID
 * @property {number} votes    Number of Votes
 * @property {number} comments Number of Comments > Calculated
 * @property {number} views    Number of Views
 * @property {Date} createdAt  Timestamp Created
 */

/**
 * Post Data to be used for PostCard
 * @typedef {Object} Post
 * @property {string} _id         Question ID
 * @property {string} question    Question Text
 * @property {number} views       Number of Views
 * @property {number} votes       Number of Votes
 * @property {[string]?} tags      Question Tags
 * @property {string} author      Author User ID
 * @property {boolean} subscribe  User's subscription to the Question > Check user
 * @property {number} answers     Number of Answers > Calculated
 * @property {PostAnswer?} answer Answer itself
 * @property {Date} createdAt     Timestamp Created
 */

/**
 * Get Feed
 * @param  {Object} data     Data Object
 * @param  {string} data._id User ID
 * @return {[Post]}          Return post array data to be used for Postcard
 */
function getFeed({ _id }) {
  const questionsDoc = findTopicCollection('Secondary School').questions;
  return Object.keys(questionsDoc).map(key => {
    const {
      question,
      views,
      votes,
      tags,
      author,
      createdAt,
      answers,
    } = questionTemplate(questionsDoc[key]);

    let answer = null;
    const answerKeys = Object.keys(answers);
    if (answerKeys.length > 0) {
      const ansRaw = answers[answerKeys[0]];
      answer = {
        answer: ansRaw.answer,
        author: ansRaw.author,
        votes: ansRaw.votes,
        comments: Object.keys(ansRaw.comments).length,
        views: ansRaw.views,
        createdAt: ansRaw.createdAt,
      };
    }

    return {
      _id: key,
      question,
      views,
      votes,
      tags,
      author,
      subscribe: true, // Check with backend if user is subscribed to this question
      answers: answerKeys.length,
      answer,
      createdAt,
    };
  });
}

export function getQuestion({ _id }) {
  const q = findQuestion(_id);
  if (!q) return null;
  const { question, topic } = JSON.parse(JSON.stringify(q));
  question._id = _id;
  question.topic = topic;
  let comments = question.comments;
  if (!isEmpty(comments))
    question.comments = Object.keys(comments).map(key => ({
      ...comments[key],
      _id: key,
    }));
  else question.comments = [];
  let answers = question.answers;
  if (!isEmpty(answers)) {
    question.answers = Object.keys(answers).map(key => ({
      ...answers[key],
      _id: key,
      updatedAt: new Date(answers[key].updatedAt),
    }));
    question.answers = question.answers.map(answer => {
      const comments = answer.comments;
      return {
        ...answer,
        comments: !isEmpty(comments)
          ? Object.keys(comments).map(key => ({ ...comments[key], _id: key }))
          : [],
      };
    });
  } else question.answers = [];
  question.subscribe = false; // Get Backend
  return question;
}

/**
 * Create Answer
 * @param {Object} data        Data Object
 * @param {string} data._id    Question ID
 * @param {string} data.answer Answer text
 * @param {string} data.author Answer author
 * @param  {{url: string, name: string}[]?} data.images Image Object
 * @return {Object}            Return Answer Data and Question ID
 */
function addAnswer({ _id: question, answer, author, images }) {
  const _id = uuidGenerator();
  const a = answerTemplate({ answer, author, images });
  findQuestionCollection(question).questions[question].answers[_id] = a;
  return { ...serializeAnswer(_id, a) };
}

/**
 * Get Post for Topic Discussion Page
 * @param {Object} data       Data Object
 * @param {string} data.topic Topic
 * @return {Promise<[Post]>}  Return Promise
 */
function getPosts({ topic }) {
  const collection = findTopicCollection(topic);
  if (!collection) return [];
  const questionsDoc = collection.questions;
  return Object.keys(questionsDoc).map(key => {
    const {
      question,
      views,
      votes,
      tags,
      author,
      createdAt,
      answers,
    } = questionTemplate(questionsDoc[key]);

    return {
      _id: key,
      question,
      views,
      votes,
      tags,
      author,
      subscribe: false, // Check with backend if user is subscribed to this question
      answers: !isEmpty(answers) ? Object.keys(answers).length : 0,
      createdAt,
    };
  });
}

function getAllTopics() {
  return Object.keys(database.topics).map(key => {
    const { questions, topic, updatedAt } = topicTemplate(database.topics[key]);
    return {
      topic,
      active: updatedAt,
      questions: !isEmpty(questions) ? Object.keys(questions).length : 0,
      followers: 10, //To be calculated
    };
  });
}

function getActivities({ _id }) {
  const array = [];
  Object.keys(database.topics)
    .map(key => database.topics[key])
    .map(({ questions }) => {
      Object.keys(questions).forEach(key => {
        const questionDoc = questions[key];
        const question = questionDoc.question;
        const questionID = key;
        if (questionDoc.author === _id) {
          const {
            views,
            votes,
            tags,
            author,
            createdAt,
            answers,
          } = questionTemplate(questionDoc);
          array.push({
            _id: key,
            question,
            views,
            votes,
            tags,
            author,
            subscribe: true, //Fixed for now
            answers: !isEmpty(answers) ? Object.keys(answers).length : 0,
            createdAt,
          });
        }
        if (!isEmpty(questionDoc.answers)) {
          const answerCollection = questionDoc.answers;
          Object.keys(answerCollection).forEach(key => {
            const answerDoc = answerCollection[key];
            if (answerDoc.author === _id) {
              array.push({
                question,
                answer: answerDoc.answer,
                _id: questionID,
              });
            }
          });
        }
      });
    });
  return array;
}

function createComment({ comment, answer_id, question_id, author }) {
  const _id = uuidGenerator();
  const com = commentTemplate({ comment, author });
  const applied = Object.keys(database.topics).find(key => {
    const question = database.topics[key].questions[question_id];
    if (question) {
      if (!answer_id) return (question.comments[_id] = com);
      else if (question.answers[answer_id])
        return (question.answers[answer_id].comments[_id] = com);
    }
    return false;
  });
  return applied ? { _id, ...com } : null;
}

function updateVote({ question_id, answer_id, comment_id, vote, author }) {
  return true;
}

// Dev tools
const prefix = 'osedu/forum/';
const DEV_REQUEST = `${prefix}DEV_REQUEST`; //For Dev
const DEV_SUCCESS = `${prefix}DEV_SUCCESS`; //For Dev
const DEV_FAIL = `${prefix}DEV_FAIL`; //For Dev

/**
 * For Development Uploadfile Bypass, to be replaced by S3 uploadFile Method
 * @param  {Blob} blob       Image Blob
 * @return {Promise<string>} Return Image Url
 */
export function uploadFile(blob) {
  return dispatch => {
    return dispatch({
      types: [DEV_REQUEST, DEV_SUCCESS, DEV_FAIL],
      promise: _ =>
        new Promise(res => {
          var a = new FileReader();
          a.onload = _ => res(a.result);
          a.readAsDataURL(blob);
        }),
    });
  };
}
