import { combineReducers } from 'redux';
import { schemas } from 'redux/middleware/clientMiddleware';
import { list as listUsers, read as readUser } from './users';
import { list as listAnswers } from './answers';

const LIST_REQUEST = 'osedu/questions/LIST_REQUEST';
const LIST_SUCCESS = 'osedu/questions/LIST_SUCCESS';
const LIST_FAIL = 'osedu/questions/LIST_FAIL';
const CREATE_REQUEST = 'osedu/questions/CREATE_REQUEST';
const CREATE_SUCCESS = 'osedu/questions/CREATE_SUCCESS';
const CREATE_FAIL = 'osedu/questions/CREATE_FAIL';
const READ_REQUEST = 'osedu/questions/READ_REQUEST';
const READ_SUCCESS = 'osedu/questions/READ_SUCCESS';
const READ_FAIL = 'osedu/questions/READ_FAIL';
const UPDATE_REQUEST = 'osedu/questions/UPDATE_REQUEST';
const UPDATE_SUCCESS = 'osedu/questions/UPDATE_SUCCESS';
const UPDATE_FAIL = 'osedu/questions/UPDATE_FAIL';
const REMOVE_REQUEST = 'osedu/questions/REMOVE_REQUEST';
const REMOVE_SUCCESS = 'osedu/questions/REMOVE_SUCCESS';
const REMOVE_FAIL = 'osedu/questions/REMOVE_FAIL';
const LOAD_REQUEST = 'osedu/questions/LOAD_REQUEST';
const LOAD_SUCCESS = 'osedu/questions/LOAD_SUCCESS';
const LOAD_FAIL = 'osedu/questions/LOAD_FAIL';
const FEED_REQUEST = 'osedu/questions/FEED_REQUEST';
const FEED_SUCCESS = 'osedu/questions/FEED_SUCCESS';
const FEED_FAIL = 'osedu/questions/FEED_FAIL';
const VOTE_REQUEST = 'osedu/questions/VOTE_REQUEST';
const VOTE_SUCCESS = 'osedu/questions/VOTE_SUCCESS';
const VOTE_FAIL = 'osedu/questions/VOTE_FAIL';

// const initialState = {
//   loaded: false
// };

function isFetching(state = false, action = {}) {
  switch (action.type) {
    case LIST_REQUEST:
    case CREATE_REQUEST:
    case READ_REQUEST:
    case UPDATE_REQUEST:
    case REMOVE_REQUEST:
    case LOAD_REQUEST:
    case FEED_REQUEST:
    case VOTE_REQUEST:
      return true;
    case LIST_SUCCESS:
    case CREATE_SUCCESS:
    case READ_SUCCESS:
    case UPDATE_SUCCESS:
    case REMOVE_SUCCESS:
    case LOAD_SUCCESS:
    case FEED_SUCCESS:
    case VOTE_SUCCESS:
      return false;
    case LIST_FAIL:
    case CREATE_FAIL:
    case READ_FAIL:
    case UPDATE_FAIL:
    case REMOVE_FAIL:
    case LOAD_FAIL:
    case FEED_FAIL:
    case VOTE_FAIL:
      return false;
    default:
      return state;
  }
}

function ids(state = [], action = {}) {
  switch (action.type) {
    case LIST_SUCCESS:
    case LOAD_SUCCESS:
    case FEED_SUCCESS:
      return [...action.result.result];
    default:
      return state;
  }
}

function slugToId(state = {}, action = {}) {
  switch (action.type) {
    case LIST_SUCCESS:
    case LOAD_SUCCESS:
    case FEED_SUCCESS:
      return {
        ...state,
        ...action.result.data.reduce((result, question) => {
          result[question.slug] = question._id;
          return result;
        }, {}),
      };
    case READ_SUCCESS:
    case VOTE_SUCCESS:
      return {
        ...state,
        [action.result.data.slug]: action.result.result,
      };
    default:
      return state;
  }
}

function idsByPage(state = {}, action = {}) {
  switch (action.type) {
    case LIST_SUCCESS:
    case LOAD_SUCCESS:
    case FEED_SUCCESS:
      if (action.page) {
        return {
          ...state,
          [action.page]: ids(state, action),
        };
      }
      return state;
    default:
      return state;
  }
}

function total(state = null, action = {}) {
  switch (action.type) {
    case LIST_SUCCESS:
    case LOAD_SUCCESS:
    case FEED_SUCCESS:
      if (action.result && action.result.total) {
        return action.result.total;
      }
      return state;
    default:
      return state;
  }
}

const reducer = combineReducers({
  isFetching,
  ids,
  slugToId,
  idsByPage,
  total,
});

export default reducer;

// export function isFetching(globalState) {
//   return globalState.questions && globalState.questions.isFetching;
// }

export function list({ filter = {}, search, limit = 9, page = 1 } = {}) {
  return dispatch => {
    return dispatch({
      types: [LIST_REQUEST, LIST_SUCCESS, LIST_FAIL],
      promise: client =>
        client.post('/questions/list', {
          data: {
            filter,
            search,
            limit,
            skip: (page - 1) * limit,
          },
        }),
      schema: schemas.QUESTION_ARRAY,
      page,
    }).then(res => {
      const questions = res.data;
      return dispatch(
        listUsers({
          filter: {
            _id: [...new Set(questions.map(question => question.author))],
          },
        })
      ).then(() => {
        const questions = res.data;
        return dispatch(
          listAnswers({
            filter: {
              _id: [...new Set(questions.map(question => question.acceptedAnswer))],
            },
          })
        );
      }).then(() => {
        return res;
      });
    });
  };
}

export function create(data = {}) {
  return dispatch => {
    return dispatch({
      types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAIL],
      promise: client =>
        client.post('/questions/create', {
          data,
        }),
      schema: schemas.QUESTION,
    });
    // .then(res => {
    //   const question = res.data;
    //   return dispatch(readUser({ id: question.author })).then(() => {
    //     return res;
    //   });
    // });
  };
}

export function read(data = {}) {
  return dispatch => {
    return dispatch({
      types: [READ_REQUEST, READ_SUCCESS, READ_FAIL],
      promise: client =>
        client.post('/questions/get', {
          data,
        }),
      schema: schemas.QUESTION,
    }).then(res => {
      const question = res.data;
      return dispatch(readUser({ id: question.author })).then(() => {
        return res;
      });
    });
  };
}

export function update(data = {}) {
  return dispatch => {
    return dispatch({
      types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAIL],
      promise: client =>
        client.post('/questions/update', {
          data,
        }),
      schema: schemas.QUESTION,
    }).then(res => {
      const question = res.data;
      return dispatch(readUser({ id: question.author })).then(() => {
        return res;
      });
    });
  };
}

export function remove(data = {}) {
  return {
    types: [REMOVE_REQUEST, REMOVE_SUCCESS, REMOVE_FAIL],
    promise: client =>
      client.post('/questions/remove', {
        data,
      }),
  };
}

export function load({ filter = {}, limit = 9, page = 1 } = {}) {
  return {
    types: [LOAD_REQUEST, LOAD_SUCCESS, LOAD_FAIL],
    promise: client =>
      client.post('/questions/me', {
        data: {
          filter,
          limit,
          skip: (page - 1) * limit,
        },
      }),
    schema: schemas.QUESTION_ARRAY,
    page,
  };
}

export function feed({ filter = {}, limit = 9, page = 1 } = {}) {
  return {
    types: [FEED_REQUEST, FEED_SUCCESS, FEED_FAIL],
    promise: client =>
      client.post('/questions/feed', {
        data: {
          filter,
          limit,
          skip: (page - 1) * limit,
        },
      }),
    schema: schemas.QUESTION_ARRAY,
    page,
  };
}

export function vote(data = {}) {
  return {
    types: [VOTE_REQUEST, VOTE_SUCCESS, VOTE_FAIL],
    promise: client =>
      client.post('/questions/vote', {
        data,
      }),
    schema: schemas.QUESTION,
  };
}
