import path from 'path';
import imageExtensions from './image-extensions';

const extensions = new Set(imageExtensions);

export function isImageUri(uri) {
  return extensions.has(
    path
      .extname(uri)
      .slice(1)
      .toLowerCase()
  );
}

export function getAssetUrl(config, path) {
  if (config?.aws?.cf?.domain && path)
    return `https://${config.aws.cf.domain}/${path}`;
  if (config?.aws?.s3?.bucket && path)
    return `https://${config.aws.s3.bucket}.s3.amazonaws.com/${path}`;
  if (config?.baseUrl && path) return `http://${config.baseUrl}/${path}`;
  return '';
}
