import React, { useState } from 'react';

export default function Img({ altSrc, ...props }) {
  const [errored, setErrored] = useState(!props.src);

  /* eslint-disable-next-line jsx-a11y/alt-text */
  if (!errored) return <img onError={() => setErrored(true)} {...props} />;

  /* eslint-disable-next-line jsx-a11y/alt-text */
  return <img {...props} src={altSrc} />;
}
