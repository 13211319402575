const initialState = {
  users: {},
  resources: {},
  reviews: {},
  questions: {},
  answers: {},
  comments: {},
};

const reducer = (state = initialState, action) => {
  if (action.result && action.result.entities) {
    return Object.keys(state).reduce(
      (acc, entitiesType) => ({
        ...acc,
        [entitiesType]: {
          ...state[entitiesType],
          ...action.result.entities[entitiesType],
        },
      }),
      {}
    );
  }

  return state;
};

export default reducer;
