import { combineReducers } from 'redux';
import { schemas } from 'redux/middleware/clientMiddleware';
import { list as listUsers, read as readUser } from './users';

const LIST_REQUEST = 'osedu/answers/LIST_REQUEST';
const LIST_SUCCESS = 'osedu/answers/LIST_SUCCESS';
const LIST_FAIL = 'osedu/answers/LIST_FAIL';
const CREATE_REQUEST = 'osedu/answers/CREATE_REQUEST';
const CREATE_SUCCESS = 'osedu/answers/CREATE_SUCCESS';
const CREATE_FAIL = 'osedu/answers/CREATE_FAIL';
const READ_REQUEST = 'osedu/answers/READ_REQUEST';
const READ_SUCCESS = 'osedu/answers/READ_SUCCESS';
const READ_FAIL = 'osedu/answers/READ_FAIL';
const UPDATE_REQUEST = 'osedu/answers/UPDATE_REQUEST';
const UPDATE_SUCCESS = 'osedu/answers/UPDATE_SUCCESS';
const UPDATE_FAIL = 'osedu/answers/UPDATE_FAIL';
const REMOVE_REQUEST = 'osedu/answers/REMOVE_REQUEST';
const REMOVE_SUCCESS = 'osedu/answers/REMOVE_SUCCESS';
const REMOVE_FAIL = 'osedu/answers/REMOVE_FAIL';
const LOAD_REQUEST = 'osedu/answers/LOAD_REQUEST';
const LOAD_SUCCESS = 'osedu/answers/LOAD_SUCCESS';
const LOAD_FAIL = 'osedu/answers/LOAD_FAIL';
const VOTE_REQUEST = 'osedu/answers/VOTE_REQUEST';
const VOTE_SUCCESS = 'osedu/answers/VOTE_SUCCESS';
const VOTE_FAIL = 'osedu/answers/VOTE_FAIL';
const PREVIEW_REQUEST = 'osedu/answers/PREVIEW_REQUEST';
const PREVIEW_SUCCESS = 'osedu/answers/PREVIEW_SUCCESS';
const PREVIEW_FAIL = 'osedu/answers/PREVIEW_FAIL';

// const initialState = {
//   loaded: false
// };

function isFetching(state = false, action = {}) {
  switch (action.type) {
    case LIST_REQUEST:
    case CREATE_REQUEST:
    case READ_REQUEST:
    case UPDATE_REQUEST:
    case REMOVE_REQUEST:
    case LOAD_REQUEST:
    case PREVIEW_REQUEST:
    case VOTE_REQUEST:
      return true;
    case LIST_SUCCESS:
    case CREATE_SUCCESS:
    case READ_SUCCESS:
    case UPDATE_SUCCESS:
    case REMOVE_SUCCESS:
    case LOAD_SUCCESS:
    case PREVIEW_SUCCESS:
    case VOTE_SUCCESS:
      return false;
    case LIST_FAIL:
    case CREATE_FAIL:
    case READ_FAIL:
    case UPDATE_FAIL:
    case REMOVE_FAIL:
    case LOAD_FAIL:
    case PREVIEW_FAIL:
    case VOTE_FAIL:
      return false;
    default:
      return state;
  }
}

function ids(state = [], action = {}) {
  switch (action.type) {
    case LIST_SUCCESS:
    case LOAD_SUCCESS:
      return [...action.result.result];
    default:
      return state;
  }
}

function previewsByMainLevel(state = {}, action = {}) {
  switch (action.type) {
    case PREVIEW_SUCCESS:
      return {
        ...state,
        [action.filter.mainLevel]: action.result.data,
      };
    default:
      return state;
  }
}

function previewBySubject(state = {}, action = {}) {
  switch (action.type) {
    case PREVIEW_SUCCESS:
      return {
        ...state,
        [action.filter.subject]: action.result.data,
      };
    default:
      return state;
  }
}

function slugToId(state = {}, action = {}) {
  switch (action.type) {
    case LIST_SUCCESS:
    case LOAD_SUCCESS:
      return {
        ...state,
        ...action.result.data.reduce((result, answer) => {
          result[answer.slug] = answer._id;
          return result;
        }, {}),
      };
    case READ_SUCCESS:
    case VOTE_SUCCESS:
      return {
        ...state,
        [action.result.data.slug]: action.result.result,
      };
    default:
      return state;
  }
}

function idsByPage(state = {}, action = {}) {
  switch (action.type) {
    case LIST_SUCCESS:
    case LOAD_SUCCESS:
      if (action.page) {
        return {
          ...state,
          [action.page]: ids(state, action),
        };
      }
      return state;
    default:
      return state;
  }
}

function total(state = null, action = {}) {
  switch (action.type) {
    case LIST_SUCCESS:
    case LOAD_SUCCESS:
      if (action.result && action.result.total) {
        return action.result.total;
      }
      return state;
    default:
      return state;
  }
}

const reducer = combineReducers({
  isFetching,
  ids,
  previewsByMainLevel,
  previewBySubject,
  slugToId,
  idsByPage,
  total,
});

export default reducer;

// export function isFetching(globalState) {
//   return globalState.answers && globalState.answers.isFetching;
// }

export function list({ filter = {}, search, limit = 9, page = 1 } = {}) {
  return dispatch => {
    return dispatch({
      types: [LIST_REQUEST, LIST_SUCCESS, LIST_FAIL],
      promise: client =>
        client.post('/answers/list', {
          data: {
            filter,
            search,
            limit,
            skip: (page - 1) * limit,
          },
        }),
      schema: schemas.ANSWER_ARRAY,
      page,
    }).then(res => {
      const answers = res.data;
      return dispatch(
        listUsers({
          filter: {
            _id: [...new Set(answers.map(answer => answer.author))],
          },
        })
      ).then(() => {
        return res;
      });
    });
  };
}

export function create(data = {}) {
  return dispatch => {
    return dispatch({
      types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAIL],
      promise: client =>
        client.post('/answers/create', {
          data,
        }),
      schema: schemas.ANSWER,
    });
    // .then(res => {
    //   const answer = res.data;
    //   return dispatch(readUser({ id: answer.author })).then(() => {
    //     return res;
    //   });
    // });
  };
}

export function read(data = {}) {
  return dispatch => {
    return dispatch({
      types: [READ_REQUEST, READ_SUCCESS, READ_FAIL],
      promise: client =>
        client.post('/answers/get', {
          data,
        }),
      schema: schemas.ANSWER,
    }).then(res => {
      const answer = res.data;
      return dispatch(readUser({ id: answer.author })).then(() => {
        return res;
      });
    });
  };
}

export function update(data = {}) {
  return dispatch => {
    return dispatch({
      types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAIL],
      promise: client =>
        client.post('/answers/update', {
          data,
        }),
      schema: schemas.ANSWER,
    }).then(res => {
      const answer = res.data;
      return dispatch(readUser({ id: answer.author })).then(() => {
        return res;
      });
    });
  };
}

export function remove(data = {}) {
  return {
    types: [REMOVE_REQUEST, REMOVE_SUCCESS, REMOVE_FAIL],
    promise: client =>
      client.post('/answers/remove', {
        data,
      }),
  };
}

export function load({ filter = {}, limit = 9, page = 1 } = {}) {
  return {
    types: [LOAD_REQUEST, LOAD_SUCCESS, LOAD_FAIL],
    promise: client =>
      client.post('/answers/me', {
        data: {
          filter,
          limit,
          skip: (page - 1) * limit,
        },
      }),
    schema: schemas.ANSWER_ARRAY,
    page,
  };
}

export function vote(data = {}) {
  return {
    types: [VOTE_REQUEST, VOTE_SUCCESS, VOTE_FAIL],
    promise: client =>
      client.post('/answers/vote', {
        data,
      }),
    schema: schemas.ANSWER,
  };
}

export function preview(filter = {}) {
  return dispatch => {
    return dispatch({
      types: [PREVIEW_REQUEST, PREVIEW_SUCCESS, PREVIEW_FAIL],
      promise: client =>
        client.post('/answers/preview', {
          data: { filter },
        }),
      schema: schemas.ANSWER_ARRAY,
      filter,
    }).then(res => {
      const answers = res.data;
      return dispatch(
        listUsers({
          filter: {
            _id: [...new Set(answers.map(answer => answer.author))],
          },
        })
      ).then(() => {
        return res;
      });
    });
  };
}
